<template>
  <div class="contents-section">
    <div class="title-section">
      <h2 class="d-flex align-start">
        상담관리
        <v-tooltip right color="black">
          <template #activator="{ on, attrs }">
            <v-btn
              icon class="mt-1 ml-1"
              v-bind="attrs" v-on="on"
              @click="getSummary()"
              :loading="loading"
              color="primary"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>새로고침</span>
        </v-tooltip>
      </h2>
      <v-btn
        depressed
        color="primary"
        @click="registDialog = { show: true, title: null, content: null, type: 0, files: [] }"
      >
        상담등록
      </v-btn>
    </div>
    <div class="main-section">
      <search-filter
        :dateFilter="dateFilter"
        :radioFilters="radioFilters"
        :search="search"
        type="server"
        searchPlaceHolder="담당자/등록인/상담제목"
        @updateRadio="updateRadio"
        @updateSearch="updateSearch"
      />
      <v-row
        justify="space-between"
        align="center"
        class="mr-0 ml-0 mt-0 mb-0"
      >
        <v-col
          class="d-flex align-center pl-0"
        >
          <v-tooltip top color="black">
            <template #activator="{ on: tooltipOn }">
              <v-chip
                class="mt-4 mr-2 pr-1 pl-1 flex-shrink-0"
                label
                outlined
                elevation
                link
                color="success"
                v-on="tooltipOn"
                @click="excelDownloadFunc()"
                :disabled="excelDownloadList === null"
              >
                <v-icon>mdi-microsoft-excel</v-icon>
              </v-chip>
            </template>
            <span>상담목록 다운로드</span>
          </v-tooltip>
          <v-radio-group
            @change="options.itemsPerPage = $event"
            dense
            row
            :value="options.itemsPerPage"
            hide-details
          >
            <v-radio
              small
              label="50개씩 보기"
              :value="50"
            ></v-radio>
            <v-radio
              small
              label="100개씩 보기"
              :value="100"
            ></v-radio>
            <v-radio
              small
              label="500개씩 보기"
              :value="500"
            ></v-radio>
            <v-radio
              small
              label="1000개씩 보기"
              :value="1000"
            ></v-radio>
            <v-radio
              small
              label="3000개씩 보기"
              :value="3000"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="lists"
        item-key="no"
        :options="options"
        :loading="loading"
        class="elevation-0"
        dense
        checkbox-color="primary"
        :server-items-length="totalItems"
        hide-default-footer
        @update:sort-by="updateOptions('sortBy', $event)"
        @update:sort-desc="updateOptions('sortDesc', $event)"
      >
      <template #[`item.title`]="{ item }">
        <span class="cs-title" @click="contentsView(item.no)">{{ item.title }}</span>
      </template>
      <template #[`item.created`]="{ item }">
        {{ time.makeLocalTime(item.created, 'min') }}
      </template>
      <template #[`item.customer`]="{ item }">
        <v-tooltip top color="black">
          <template #activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{`
                ${item.customerName}
                (${item.customerPhone.slice(-4)})
              `}}
            </span>
          </template>
          <span>{{ item.customerPhone }}</span>
        </v-tooltip>
      </template>
      <template #[`item.manager`]="{ item }">
        <div
          style="width: max-content"
          class="d-flex align-center justify-center"
        >
          <v-chip
            class="pl-1 pr-1 mr-1"
            small label outlined
          >{{ comm.getGroupFullPath(groups, item.managerGroupNo, '그룹없음') }}</v-chip>
          {{ item.managerName }}
        </div>
      </template>
      <template #[`item.creator`]="{ item }">
        <div
          style="width: max-content"
          class="d-flex align-center justify-center"
        >
          <v-chip
            class="pl-1 pr-1 mr-1"
            small label outlined
          >{{ comm.getGroupFullPath(groups, item.creatorGroupNo, '그룹없음') }}</v-chip>
          {{ item.creatorName }}
        </div>
      </template>
      <template #[`item.status`]="{ item }">
        <div style="width: max-content" class="d-flex justify-center">
          <v-menu offset-y v-if="checkFuncPermission('status')">
            <template #activator="{ on, attrs }">
              <v-chip small link outlined
                class="pl-2 pr-2"
                v-bind="attrs"
                v-on="on"
                :color="item.status === 1
                  ? 'info'
                  : (item.status === 2 ? 'success' : '')"
              >
                <span v-if="item.status === 1">진행중</span>
                <span v-else-if="item.status === 2">처리완료</span>
                <span v-else>미확인</span>
              </v-chip>
            </template>
            <v-list>
              <v-list-item dense nav link v-if="item.status !== 0"
                @click="updateStatus(item.no, 0)"
              >
                <v-list-item-title>미확인으로 변경</v-list-item-title>
              </v-list-item>
              <v-list-item dense nav link v-if="item.status !== 1"
                @click="updateStatus(item.no, 1)"
              >
                <v-list-item-title>진행중으로 변경</v-list-item-title>
              </v-list-item>
              <v-list-item dense nav link v-if="item.status !== 2"
                @click="updateStatus(item.no, 2)"
              >
                <v-list-item-title>처리완료로 변경</v-list-item-title>
              </v-list-item>
              <v-list-item dense nav link
                @click="getHistories(item.no)"
              >
                <v-list-item-title>상담처리 이력보기</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-chip v-else small
            class="pl-2 pr-2"
            :color="item.status === 1
              ? 'info'
              : (item.status === 2 ? 'success' : '')"
          >
            <span v-if="item.status === 1">진행중</span>
            <span v-else-if="item.status === 2">처리완료</span>
            <span v-else>미확인</span>
          </v-chip>
        </div>
      </template>
      </v-data-table>
      <div class="text-center pt-4 d-flex justify-center align-center">
        <v-btn
          icon
          v-if="Math.ceil(totalItems / options.itemsPerPage) > 1"
          @click="options.page = 1"
          :disabled="options.page === 1"
        >
          <v-icon>mdi-chevron-double-left</v-icon>
        </v-btn>
        <v-pagination
          v-if="options.itemsPerPage > 0"
          small
          color="primary"
          v-model="options.page"
          :length="Math.ceil(totalItems / options.itemsPerPage)"
          totalVisible="15"
          depressed
        ></v-pagination>
        <v-btn
          icon
          v-if="Math.ceil(totalItems / options.itemsPerPage) > 1"
          @click="options.page = Math.ceil(totalItems / options.itemsPerPage)"
          :disabled="options.page === Math.ceil(totalItems / options.itemsPerPage)"
        >
          <v-icon>mdi-chevron-double-right</v-icon>
        </v-btn>
      </div>
    </div>
    <dlg-post-regist
      :show.sync="registDialog.show"
      :title="registDialog.title"
      :content="registDialog.content"
      :type="0"
      :func="getSummary"
    />
    <dlg-history
      :show.sync="historyDlg.show"
      :data="historyDlg.data"
      :title="historyDlg.title"
      :sub="historyDlg.sub"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import SearchFilter from '@/components/layout/SearchFilter.vue';
import DlgPostRegist from '@/components/dialog/DlgPostRegist.vue';
import DlgHistory from '@/components/dialog/DlgHistory.vue';
import time from '@/util/time';
import comm from '@/util/comm';

export default {
  name: 'Counseling',
  components: {
    SearchFilter,
    DlgPostRegist,
    DlgHistory,
  },
  data: () => ({
    comm,
    time,
    filePath: null,
    registDialog: {
      show: false,
      content: null,
      title: null,
      files: [],
    },
    historyDlg: {
      show: false,
      data: [],
      title: '',
      sub: '',
    },
    groups: [],
    loading: true,
    dateFilter: {
      label: '상담등록일',
      startDate: time.moment().add('-1', 'Y').format('YYYY-MM-DD'),
      startPicker: false,
      endDate: time.moment().format('YYYY-MM-DD'),
      endPicker: false,
    },
    lists: [],
    radioFilters: {
      status: {
        key: 'status',
        name: '처리상태',
        value: '전체',
        data: [
          { name: '전체', value: '전체' },
          { name: '미확인', value: 0 },
          { name: '진행중', value: 1 },
          { name: '처리완료', value: 2 },
        ],
      },
    },
    search: '',
    totalItems: 0,
    options: {
      page: 1,
      itemsPerPage: 50,
      sortBy: ['created'],
      sortDesc: [true],
      filters: [
        { condition: 'ge', column: 'created', value: time.makeUTCDate(time.moment().add('-1', 'Y').format('YYYY-MM-DD')) },
        {
          where: 'and',
          condition: 'le',
          column: 'created',
          value: `
            ${time.makeUTCDate(time.moment().format('YYYY-MM-DD'))} 23:59:59
          `,
        },
        {
          where: 'and',
          condition: 'inc',
          column: 'status',
          value: '',
        },
        {
          where: 'and',
          filters: [
            {
              condition: 'inc',
              column: 'title',
              value: '',
            },
            {
              where: 'or',
              condition: 'inc',
              column: 'creatorName',
              value: '',
            },
            {
              where: 'or',
              condition: 'inc',
              column: 'managerName',
              value: '',
            },
            {
              where: 'or',
              condition: 'inc',
              column: 'managerName',
              value: '',
            },
          ],
        },
      ],
    },
    excelDownloadList: [],
  }),
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
      route: 'router/route',
    }),
    headers() {
      return [
        {
          text: '번호',
          value: 'no',
          align: 'center',
          sortable: false,
        },
        {
          text: '처리상태',
          value: 'status',
          align: 'center',
          width: 100,
        },
        {
          text: '상담제목',
          value: 'title',
          align: 'left',
        },
        {
          text: '고객정보',
          value: 'customer',
          align: 'center',
        },
        {
          text: '담당자',
          value: 'manager',
          align: 'left',
          width: 100,
        },
        {
          text: '등록인',
          value: 'creator',
          align: 'left',
          width: 100,
        },
        {
          text: '등록일시',
          value: 'created',
          align: 'center',
        },
      ];
    },
  },
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
      post: 'dialog/post',
    }),
    ...mapActions({
      alert: 'dialog/alert',
      postInit: 'dialog/postInit',
    }),
    /* 라디오필터 업데이트 */
    updateRadio(value, key) {
      this.radioFilters[key].value = value;
    },
    /* 검색 업데이트 */
    updateSearch(value) {
      this.search = value;
    },
    getList() {
      this.$socket.emit('cs.list.get', this.options, (resp) => {
        if (resp.result === 'success') {
          this.lists = resp.items;
          this.loading = false;
          if (this.options.page > Math.ceil(this.totalItems / this.options.itemsPerPage)) {
            this.options.page = 1;
          }
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.loading = false;
        }
      });
    },
    getSummary() {
      this.loading = true;
      this.$socket.emit('cs.summary.get', {
        filters: this.options.filters,
      }, (resp) => {
        if (resp.result === 'success') {
          this.totalItems = resp.item.count;
          this.getList();
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    updateOptions(type, value) {
      let valArr = [];
      valArr = [value];
      this.options[type] = valArr;
    },
    updateStatus(no, status) {
      this.$socket.emit('cs.update', {
        no,
        status,
      }, (resp) => {
        if (resp.result === 'success') {
          this.getSummary();
          this.alert(['success', '처리상태가 변경되었습니다.']);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    contentsView(no) {
      this.progress(true);
      this.$socket.emit('cs.get', { no }, (resp) => {
        if (resp.result === 'success') {
          this.post({
            show: true,
            type: 0,
            func: () => {
              this.getSummary();
            },
            item: { ...resp.item },
          });
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    getGroupList() {
      this.$socket.emit('groups.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [false],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          this.groups = resp.items.groups;
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    getHistories(no) {
      this.progress(true);
      this.$socket.emit('log.get', {
        action: 'cs.update',
        key: String(no),
      }, (resp) => {
        if (resp.result === 'success') {
          const msgObj = {
            0: '미확인',
            1: '진행중',
            2: '처리완료',
          };
          const result = [];
          resp.items.forEach((r) => {
            if (r.after.status !== undefined) {
              const msg = msgObj[String(r.after.status)];
              result.push({ ...r, after: msg });
            }
          });
          this.historyDlg.data = result;
          this.historyDlg.show = true;
          this.historyDlg.title = '상담처리 이력';
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    excelDownloadFunc() {
      this.progress(true);
      this.excelDownloadList = null;
      this.$socket.emit('cs.list.get', {
        ...this.options,
        itemsPerPage: 0,
      }, (resp) => {
        if (resp.result === 'success') {
          if (resp.items.length <= 0) {
            this.alert(['error', '다운로드할 목록이 없습니다.']);
            this.excelDownloadList = [];
            this.progress(false);
          } else {
            const list = resp.items.reduce((arr, r) => {
              const statusArr = ['미확인', '진행중', '처리완료'];
              const row = {};
              row['상담번호'] = r.no;
              row['제목'] = r.title;
              row['고객이름'] = r.customerName;
              row['고객전화번호'] = comm.makePrivatePhoneNumber(r.customerPhone);
              row['담당자'] = `${comm.getGroupFullPath(this.groups, r.managerGroupNo, '그룹없음 ')}${r.managerName}`;
              row['등록인'] = `${comm.getGroupFullPath(this.groups, r.creatorGroupNo, '그룹없음 ')}${r.creatorName}`;
              row['등록일시'] = time.makeLocalTime(r.created, 'min');
              row['처리상태'] = statusArr[r.status];
              arr.push(row);
              return arr;
            }, []);
            this.excelDownloadList = list;
            comm.makeExcelFile(this.excelDownloadList, `상담목록[${time.moment().format('YYYY-MM-DD HH_mm_ss')}].xlsx`);
            this.progress(false);
          }
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
          this.excelDownloadList = [];
        }
      });
    },
    checkFuncPermission(funcName) {
      try {
        const userPermission = { ...this.userInfo.permission.cs.menu.cs.func };
        let result = false;
        if (
          userPermission[funcName] !== undefined
          && userPermission[funcName].bool === true
        ) result = true;
        return result;
      } catch (error) {
        console.error('checkFuncPermission error');
        return false;
      }
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val !== null) {
          this.getGroupList();
          this.getSummary();
        }
      },
    },
    options: {
      deep: true,
      handler() {
        if (this.userInfo != null && this.totalItems >= 0) {
          this.getSummary();
        }
      },
    },
    dateFilter: {
      deep: true,
      handler(val) {
        if (this.userInfo != null && val.endPicker === false && val.startPicker === false) {
          this.options.filters[0].value = time.makeUTCDate(val.startDate);
          this.options.filters[1].value = time.makeUTCDate(
            `${val.endDate} 23:59:29`,
          );
        }
      },
    },
    radioFilters: {
      deep: true,
      handler(val) {
        if (this.userInfo != null) {
          const value = val.status.value === '전체' ? '' : val.status.value;
          this.options.filters[2].value = value;
        }
      },
    },
    search: {
      handler(val) {
        if (this.userInfo != null) {
          this.options.filters[3].filters[0].value = val;
          this.options.filters[3].filters[1].value = val;
          this.options.filters[3].filters[2].value = val;
          this.options.filters[3].filters[3].value = val;
        }
      },
    },
  },
  mounted() {
  },
};
</script>

<style scoped lang="scss">
.cs-title {
  cursor: pointer;
  display: block;
  max-width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:hover {
    // text-decoration: underline;
    color: darkblue;
  }
}
</style>
